const Navbar = () => {
  return (
    <header>
      <nav className="navbar" id="navbar">
        <div className="navbar-container container">
          <input type="checkbox" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <ul className="menu-items">
            <li>
              <a href="https://mirzajewels.com/admin/dashboard">Dashboard</a>
            </li>
            <hr />
            <li>
              <a href="https://mirzajewels.com/transactions">Customers</a>
            </li>
            <hr />
            <li>
              <a href="https://mirzajewels.com/history">Bills History </a>
            </li>
            <hr />
            <li>
              <a href="https://mirzajewels.com/queries">Queries</a>
            </li>
            <hr />
            <li>
              <a href="https://mirzajewels.com/gsthome">Create +</a>
            </li>
            <hr />
            {/* <form id="logout" action="#" method="post">
              <li>
                <a className="logout" href="https://mirzajewels.com/logout">
                  Logout
                </a>
              </li>
            </form> */}
          </ul>
          <a className="logo-a" href="/">
            <img className="logo" src="/images/logo.png" alt="logo" />
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
