import { useContext, useEffect, useState } from "react";
import { billContext } from "../billContext";
import _ from "lodash";

const BillingForm = ({ step, setModal }) => {
  const { bill, setBill } = useContext(billContext);
  const [item, setItem] = useState({
    description: "",
    weight: "",
    quantity: "",
    amount: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    setItem((prevItem) => ({
      ...prevItem,
      amount: Number(
        (prevItem.weight * (bill.goldRate / 10) * prevItem.quantity).toFixed(2)
      ),
    }));
  }
  useEffect(() => {
    if (item.amount !== "") {
      setBill((prevBill) => ({
        ...prevBill,
        items: [...prevBill.items, item],
      }));
      setModal(false);
    }
  }, [item.amount]);

  return (
    <div className="modalWrap">
      <div className="formWrap">
        <h1 className="formTitle">Add Item</h1>
        <form
          style={{ textAlign: "right" }}
          className="form"
          onSubmit={handleSubmit}
        >
          <input
            value={item.description}
            placeholder="Item Description"
            onChange={(e) =>
              setItem({ ...item, description: _.capitalize(e.target.value) })
            }
            type="text"
            required
          />
          <input
            value={item.weight}
            placeholder="Weight"
            onChange={(e) =>
              setItem({ ...item, weight: parseFloat(e.target.value) })
            }
            type="number"
            required
            step={0.01}
          />
          <input
            value={item.quantity}
            placeholder="Quantity"
            onChange={(e) =>
              setItem({ ...item, quantity: parseInt(e.target.value) })
            }
            type="number"
            required
          />
          <button
            onClick={() => setModal(false)}
            type="button"
            className="greyBtn"
          >
            Cancel
          </button>
          <button type="submit" className="generateBtn goldBtn">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default BillingForm;
