import { useContext, useEffect, useState } from "react";
import BillingForm from "./BillingForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { billContext } from "../billContext";
import toast from "react-hot-toast";
import Axios from "axios";
import Printing from "./Printing";

const ItemsPage = ({ step }) => {
  const [modal, setModal] = useState(false);
  const { bill, setBill } = useContext(billContext);
  const [printing, setPrinting] = useState(false);

  function handleDeleteItem(index) {
    setBill((prevBill) => {
      const updatedItems = [...prevBill.items];
      updatedItems.splice(index, 1);
      return { ...prevBill, items: updatedItems };
    });
  }
  function handleAdd() {
    if (bill.items.length === 6) {
      toast.error("Maximum 6 items are billed at a time");
    } else {
      setModal(true);
    }
  }

  function handleInvoice() {
    if (bill.items.length === 0) {
      toast.error("Please add one or more items");
    } else {
      setPrinting(true);
      Axios.post("https://mirzajewels.com/b2c/generate-invoice", bill, {
        responseType: "blob",
        withCredentials: true,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(response.data);

          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = url;

          // Extract the filename from the response headers
          const filename = response.headers
            .get("Content-Disposition")
            .match(/filename="([^"]+)"/)[1];

          // Set the filename for the download
          link.setAttribute("download", filename);

          // Append the link to the document and trigger the click event to start the download
          document.body.appendChild(link);
          link.click();

          // Clean up the URL and the link element
          window.URL.revokeObjectURL(url);
          setTimeout(() => setPrinting(false), 2000);
        })
        .catch((err) => {
          setPrinting(false);
          console.log(err);
          toast.error("Something Went Wrong!");
        });
    }
  }

  return printing ? (
    <Printing />
  ) : (
    <div style={{ width: "60%" }} className="itemsDiv">
      <span>
        <b>Gold Rate:</b> Rs.{bill.goldRate}/-
      </span>
      <span>
        <b>Exchange Value:</b>{" "}
        {bill.exchange.value
          ? bill.exchange.value && bill.exchange.valueIn === "grams"
            ? `${bill.exchange.value}g`
            : `Rs.${bill.exchange.value}/-`
          : "NA"}
      </span>
      <span style={{ marginRight: "1rem" }}>
        <b>Lst.wt: </b> {bill.lstWeight ? bill.lstWeight + "g" : "NA"}
      </span>
      <span style={{ marginRight: "1rem" }}>
        <b>Value Added: </b> {bill.valueAdded}g
      </span>

      <div className="tableContainer">
        <table className="itemsTable">
          <thead>
            <tr>
              <td>Sl.no</td>
              <td>Item</td>
              <td>Weight</td>
              <td>Quantity</td>
              <td>Amount</td>
              <td>Remove</td>
            </tr>
          </thead>
          <tbody>
            {bill.items &&
              bill.items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="description">{item.description}</td>
                  <td>{item.weight + "g"}</td>
                  <td>x {item.quantity}</td>
                  <td>{"Rs." + item.amount.toLocaleString("en-IN")}</td>
                  <td
                    className="itemDelete"
                    onClick={() => handleDeleteItem(index)}
                  >
                    <FontAwesomeIcon style={{ color: "red" }} icon={faMinus} />
                  </td>
                </tr>
              ))}
            {bill.overallAmount ? (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ color: "green" }}>
                  <b>
                    Total: Rs.{bill.overallAmount.toLocaleString("en-IN")}/-
                  </b>
                </td>
                <td></td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <button type="button" className="greyBtn" onClick={() => step(false)}>
            Back
          </button>
          <button onClick={() => handleAdd()} className="goldBtn addItemBtn">
            + Add Item
          </button>
        </div>
        <button className="generateBtn greenBtn" onClick={handleInvoice}>
          Generate Invoice
        </button>
      </div>
      {modal ? <BillingForm setModal={setModal} step={step} /> : null}
    </div>
  );
};

export default ItemsPage;
