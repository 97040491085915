import { Link } from "react-router-dom";

const Printing = () => {
  return (
    <section className="printSection">
      <div className="printingWrap">
        <div className="printimg"></div>
      </div>
    </section>
  );
};

export default Printing;
