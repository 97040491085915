import { useRef, useState } from 'react';
import CustomerForm from '../components/CustomerForm';
import ItemsPage from '../components/itemsPage';

const CustomerBilling = ()=>{

    const [ first, setFirst ] = useState(false);

    function step(bool){
        setFirst(bool);
    }

    return(
        <section className="body-wrap">
                {
                    first? 
                    <ItemsPage step={step} /> 
                    : <CustomerForm step={step} />
                }
        </section>
    )
}

export default CustomerBilling;