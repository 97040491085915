import { Link } from "react-router-dom";

const ErrorPage = (errorCode)=>{

    return(
        <section class="page_404">
            <div class="container">
                <div class="row">	
                <div class="col-sm-12 ">
                <div class="col-sm-10 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                    {/* <h1 class="text-center ">401</h1> */}
                </div>
                
                <div class="contant_box_404">
                <h3 class="h2" style={{marginBottom: "1rem"}}>
                Something Went Wrong
                </h3>
                
                <p>the page you are looking for is not accessible!</p>
                
                <button onClick={() => window.location.href = "https://mirzajewels.com"} className="link_404">Go to Home</button>
            </div>
                </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default ErrorPage;