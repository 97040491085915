import { useContext, useEffect, useState } from "react";
import { billContext } from "../billContext";
import { toast } from "react-hot-toast";
import _ from "lodash";

const CustomerForm = ({ step }) => {
  var { bill, setBill } = useContext(billContext);
  var [billDetails, setBillDetails] = useState({ exchange: {} });

  function formatDate(date) {
    const inputDate = new Date(date);

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Note: Months are zero-based, so add 1
    const year = inputDate.getFullYear();

    return `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setBill({
      ...bill,
      name: _.capitalize(billDetails.name),
      address: _.capitalize(billDetails.address),
      mobile: billDetails.mobile,
      date: billDetails.date,
      goldRate: billDetails.goldRate,
      lstWeight: billDetails.lstWeight,
      valueAdded: billDetails.valueAdded,
      exchange: {
        ...billDetails.exchange,
        value: billDetails.exchange.value ? billDetails.exchange.value : null,
        valueIn: billDetails.exchange.valueIn
          ? billDetails.exchange.valueIn
          : null,
      },
    });
    step(true);
  }
  useEffect(() => {
    const { items, ...withoutItems } = bill;
    setBillDetails(withoutItems);
  }, []);

  return (
    <div className="formWrap">
      <h1 className="formTitle">Invoice Details</h1>
      <form className="form" onSubmit={handleSubmit}>
        <input
          placeholder="Name"
          value={billDetails.name}
          onChange={(e) =>
            setBillDetails({ ...billDetails, name: e.target.value })
          }
          type="text"
          required
        />
        <input
          placeholder="Address"
          value={billDetails.address}
          onChange={(e) =>
            setBillDetails({ ...billDetails, address: e.target.value })
          }
          type="text"
          required
        />
        <input
          placeholder="Mobile"
          value={billDetails.mobile}
          onChange={(e) =>
            setBillDetails({ ...billDetails, mobile: e.target.value })
          }
          type="tel"
          required
        />
        <input
          placeholder="Date"
          value={billDetails.date}
          onChange={(e) =>
            setBillDetails({ ...billDetails, date: e.target.value })
          }
          type="date"
          required
        />
        <input
          value={billDetails.goldRate ? billDetails.goldRate : ""}
          placeholder="Gold Rate"
          onChange={(e) =>
            setBillDetails({
              ...billDetails,
              goldRate: parseFloat(e.target.value),
            })
          }
          type="number"
          required
        />
        <input
          value={billDetails.lstWeight ? billDetails.lstWeight : ""}
          placeholder="LST Weight(In Grams)"
          onChange={(e) =>
            setBillDetails({
              ...billDetails,
              lstWeight: parseFloat(e.target.value),
            })
          }
          type="number"
          step="0.1"
        />
        <input
          value={billDetails.valueAdded ? billDetails.valueAdded : ""}
          placeholder="Value Added(In Grams)"
          onChange={(e) =>
            setBillDetails({
              ...billDetails,
              valueAdded: parseFloat(e.target.value),
            })
          }
          type="number"
          step="0.1"
          required
        />
        <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
          <label>Exchange Value In</label> <br />
          <input
            type="radio"
            id="inRupees"
            name="valueIn"
            onChange={() =>
              setBillDetails({
                ...billDetails,
                exchange: { ...billDetails.exchange, valueIn: "rupees" },
              })
            }
            required={
              billDetails.exchange &&
              typeof billDetails.exchange.value !== "undefined" &&
              !isNaN(billDetails.exchange.value) &&
              billDetails.exchange.value !== null &&
              billDetails.exchange.value !== ""
            }
          />
          <label style={{ marginRight: "1rem" }} htmlFor="inRupees">
            Rupees
          </label>
          <input
            type="radio"
            id="inGrams"
            name="valueIn"
            onChange={() =>
              setBillDetails({
                ...billDetails,
                exchange: { ...billDetails.exchange, valueIn: "grams" },
              })
            }
          />
          <label htmlFor="inGrams">Grams</label>
        </div>

        <input
          value={billDetails.exchange.value ? billDetails.exchange.value : ""}
          placeholder="Exchange Value"
          onChange={(e) =>
            setBillDetails({
              ...billDetails,
              exchange: {
                ...billDetails.exchange,
                value: parseFloat(e.target.value),
              },
            })
          }
          type="number"
        />
        <button className="goldBtn" type="submit">
          Continue
        </button>
      </form>
    </div>
  );
};

export default CustomerForm;
