import "./App.css";
import "./css/responsiveNav.css";
import "./css/form.css";
import "./css/modal.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import CustomerBilling from "./pages/CustomerBilling";
import Navbar from "./components/Navbar";
import { useEffect, useState } from "react";
import Axios from "axios";
import { billContext } from "./billContext";
import { Toaster } from "react-hot-toast";
import ErrorPage from "./components/ErrorPage";
import Printing from "./components/Printing";
import { faHourglass1 } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [bill, setBill] = useState({
    name: "",
    address: "",
    mobile: "",
    date: "",
    exchange: {},
    items: [],
  });
  const [authenticated, setAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get("https://mirzajewels.com/auth/isauth", {
          withCredentials: true,
        });
        if (res.status === 200) {
          setAuthenticated(true);
        }
      } catch (err) {
        console.log(err);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {authenticated ? (
        <billContext.Provider value={{ bill, setBill }}>
          <div>
            <Toaster
              position="top-center"
              toastOptions={{ duration: 3000 }}
            ></Toaster>
          </div>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<CustomerBilling />} />
            </Routes>
          </BrowserRouter>
        </billContext.Provider>
      ) : (
        <h1>Bad Request</h1>
      )}
    </>
  );
}

export default App;
